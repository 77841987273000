import { useQuery } from '@apollo/client'
import { developerGql } from '@nemlia/hasura-gql'

const GET_NOTIFICATIONS_BY_USER = developerGql(/* GraphQL */ `
  query S_GET_NOTIFICATIONS_BY_USER($user_id: uuid!) {
    ta_notification_user(
      where: { user_id: { _eq: $user_id } }
      limit: 50
      order_by: { ta_notification: { created_at: desc } }
    ) {
      state
      state_updated_at
      ta_user {
        id
        full_name
      }
      ta_notification {
        id
        config
        created_at
        checked
        comment
        notification_text
        ta_notification_users {
          state
          state_updated_at
          ta_user {
            id
            full_name
          }
        }
        ta_notification_reminders(order_by: { created_at: desc }) {
          id
          created_at
        }
        ta_rule_notification {
          id
          title
          notification_text
          note
          config
          ta_sensor {
            sensor_name
            sensor_type
            ta_device {
              id
              name
              location
              room
              eui
              organisation_id
              ta_citizen_devices {
                ta_citizen {
                  citizen_name
                  citizen_address
                }
              }
            }
          }
        }
      }
    }
  }
`)
export const useGetNotificationsByUser = (user_id: string) => {
  const { data, loading, error } = useQuery(GET_NOTIFICATIONS_BY_USER, {
    variables: { user_id },
    pollInterval: 5000,
  })
  return { data, loading, error }
}
